/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-undef */
import './App.css';
import React, { useEffect, useMemo, useState } from 'react';
import twitter from './assets/x.png';
import navbar from './assets/navigation-bar.png';
import wrong from './assets/wrong.png';
import logoPic from './assets/logo.png';
import x from './assets/twitter.png';
import tg from './assets/telegram.png';
import instagram from './assets/instagram.png';
import mail from './assets/email.png';
import copy from './assets/copy.png';
import Footer from './components/footer';
import border from './assets/border.png';
import frog from './assets/3.jpeg';
import note from './assets/note.png';
import pen from './assets/pen.png';

import $ from 'jquery';
import About from './components/about';
import Intro from './components/intro';
import Token from './components/token';

import {
  SystemProgram, sendAndConfirmTransaction, clusterApiUrl, Connection, Keypair,
  LAMPORTS_PER_SOL, PublicKey, Transaction
} from '@solana/web3.js';

import './buffer-polyfill';



$(window).on('load', function () {
  setTimeout(function () { // allowing 3 secs to fade out loader
    $('.page-loader').fadeOut('slow');
  }, 2000);
});



const xLink = () => {
  window.open("https://twitter.com/FROGY_TOKEN");
}

const insta = () => {
  window.open("#");
}

const teleG = () => {
  window.open("#");
}

const Home = () => {

  const [_navbarOpen, set_navbarOpen] = useState(0);
  const [_presaleOpen, set_presaleOpen] = useState(0);
  const [walletObject, setwalletObject] = useState(null);
  const [walletAddress, setWalletAddress] = useState(null);
  const [myWalletbalance, setMywalletBalance] = useState(0);
  const [toTokenAccount, setToTokenAccount] = useState(null);
  const [keyPair, setKeyPair] = useState(null);
  const [_tokenAmount, set_tokenAmount] = useState('');
  const [response, setResponse] = useState('');

  const [_days, setDays] = useState(0);
  const [_hours, setHours] = useState(0);
  const [_minutes, setMinutes] = useState(0);
  const [_seconds, setSeconds] = useState(0);
  const [_signature, setSignature] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  const updateCountdown = async () => {
    const now = new Date();
    const timeLeft = Math.max(0, 1716681600000 - now);

    const days = Math.floor(timeLeft / (1000 * 60 * 60 * 24));
    const hours = Math.floor(timeLeft % (1000 * 60 * 60 * 24) / (1000 * 60 * 60));
    const minutes = Math.floor((timeLeft % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((timeLeft % (1000 * 60)) / 1000);

    setDays(days < 10 ? '0' + days : days.toString());
    setHours(hours < 10 ? '0' + hours : hours.toString());
    setMinutes(minutes < 10 ? '0' + minutes : minutes.toString());
    setSeconds(seconds < 10 ? '0' + seconds : seconds.toString());

  }

  const sendMoney2 = async () => {
    try {
      // console.log("walletObject.publicKey" + walletObject.publicKey);
      // console.log("walletObject:", JSON.stringify(walletObject, null, 2));

      // const connection = new Connection(clusterApiUrl("mainnet-beta"), "confirmed");
      const connection = new Connection('https://solana-mainnet.g.alchemy.com/v2/Vq5hOlVbvfehBzl2qJx7TzBeH_txV-D9', 'finalized');

      // Transfer SOL
      const price = _tokenAmount * LAMPORTS_PER_SOL;

      const transaction = new Transaction().add(
        SystemProgram.transfer({
          fromPubkey: walletObject.publicKey,
          toPubkey: new PublicKey('54RaX2tjjpDcWNSzkPn5ychQauyGBYinnb2ZLvsvPrn8'),
          lamports: price,
        }),
      );


      //Source Account
      transaction.feePayer = walletObject.publicKey;
      transaction.recentBlockhash = (await connection.getLatestBlockhash()).blockhash;

      let signature;

      signature = await window.solana.signAndSendTransaction(transaction);

      console.log('Transaction sent:', signature);

      if (signature != null) {
        setResponse("Transaction Successful");
        setSignature(true);

      } else {
        setResponse("Please check again");
      }

    } catch (err) {
      console.log(err);
    }
  }

  const checkIfWalletIsConnected = async () => {
    try {
      const { solana } = window;
      console.log("solana :" + solana);
      if (solana) {
        if (solana.isPhantom) {
          console.log("Phantom wallet found!");
          const response = await solana.connect({
            onlyIfTrusted: true,
          });
          console.log("Connected with public key!", response.publicKey.toString());
          setWalletAddress(response.publicKey.toString());

          // Set up wallet object for later use
          setwalletObject(response);
        }

      } else {
        alert("Solana object not found! Get a Phantom wallet")
      }



    } catch (error) {
      console.error(error);
    }
  };


  useEffect(() => {

    /*const onLoad = async () => {
      await checkIfWalletIsConnected();
    };
    window.addEventListener("load", onLoad);
    return () => window.removeEventListener("load", onLoad);*/

    const detectMobile = () => {
      const userAgent = navigator.userAgent.toLowerCase();
      return /android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(userAgent);
    };

    // Check if the user is on a mobile device
    setIsMobile(detectMobile());

    // Redirect if the user is on a mobile device
    if (isMobile) {
      window.location.href = 'https://phantom.app/ul/browse/https%3A%2F%2Fmagiceden.io%2Fitem-details%2F8yjN8iRuoiYiKW487cnW9vn6mLBR5E8aCNKsBRmTP9vN?ref=https%3A%2F%2Fmagiceden.io';
    }

    setInterval(updateCountdown, 1000);


  }, [isMobile]);


  const renderNotConnectedContainer = () => (
    <button onClick={connectWallet}>Connect to Wallet</button>
  );

  async function closeNav() {
    set_navbarOpen(0);
    //this.setState({ _navbarOpen: 0 });
  }

  async function navbarOpen() {
    set_navbarOpen(1);
    //this.setState({ _navbarOpen: 0 });
  }

  async function presaleOpen() {
    set_presaleOpen(1);
    //this.setState({ _navbarOpen: 0 });
  }

  async function presaleClose() {
    set_presaleOpen(0);
    //this.setState({ _navbarOpen: 0 });
  }

  const _disconnectWallet = async () => {
    try {
      //  await walletObject.disconnect();
      console.log("Disconnected from wallet");
      setWalletAddress(null);
      setwalletObject(null);
      window.location.reload(true);

    } catch {
      console.log(err);
    }
  };

  const sendMoney = async () => {

    // console.log("walletObject.publicKey" + walletObject.publicKey);
    // console.log("walletObject:", JSON.stringify(walletObject, null, 2));

    const connection = new Connection(clusterApiUrl("mainnet-beta"), "confirmed");

    // Transfer SOL
    const price = 0.05 * LAMPORTS_PER_SOL;

    const transaction = new Transaction().add(
      SystemProgram.transfer({
        fromPubkey: new PublicKey('5cBcVX1dkh1L3H8JQ9W1JvWy7WmE2GK2c16t5jrXZdtr'),
        toPubkey: new PublicKey('9KByvqwXpGcZJTfeDeRc2HVxvPAGbJz9nokVyp4FoHZt'),
        lamports: 1000000000,
      }),
    );


    //Source Account
    transaction.feePayer = walletObject.publicKey;
    transaction.recentBlockhash = (await connection.getLatestBlockhash()).blockhash;

    let signature;

    signature = await window.solana.signAndSendTransaction(transaction);

    console.log('Transaction sent:', signature);

    /*if (signature != null) {
      setResponse("Transaction Successful");
    } else {
      setResponse("Please check again");
    }*/

  }

  const connectWallet = async () => {
    try {
      const { solana } = window;
      if (solana) {
        // Connect to the user's wallet and get their public key
        const response = await solana.connect();
        setWalletAddress(response.publicKey.toString());
        setwalletObject(response);

        // Sign a message using the user's wallet
        const message = Uint8Array.from([...new TextEncoder().encode('Welcome to FROGY')]);
        const signedMessage = await solana.signMessage(message);

        // Check if the message property exists before converting it to an array
        let serializedMessage;
        if ('message' in signedMessage) {
          serializedMessage = Array.from(signedMessage.message);
        } else {
          serializedMessage = Array.from(Uint8Array.from(Buffer.from(signedMessage.signature)));
        }

        // Add the signature and serialized message to the response object
        response.signature = signedMessage.signature;
        response.serializedMessage = serializedMessage;

        console.log("Connected with public key:", response.publicKey.toString());
        console.log("Signature:", signedMessage.signature.toString());
        console.log("Serialized Message:", serializedMessage);

      }

    } catch (err) {
      console.log(err);
    }
  };



  return (
    <div class="allWrap">
      <div class="light">
        {_navbarOpen < 1 ?
          (<div class="miniBarMain">
            <div class="logoDiv"><img onClick={() => window.location.href = 'Mint'} src={logoPic} /></div>

            <div class="navbarMain"><img class="navbar" onClick={navbarOpen} src={navbar} /></div>
          </div>) :
          (<div class="miniBarMain">
            <div class="logoDiv"><img onClick={() => window.location.href = 'Mint'} src={logoPic} /></div>

            <div class="navbarMain"><img class="navbar" onClick={closeNav} src={wrong} /></div>
          </div>)}

        <div class="headers">

          <div class="h1">

            <div class="logoDiv">
              <img src={logoPic} />
              <div className='navT'>FROGY</div>
            </div>

            <div class="right">

              <div class="connect">
                <img className='x' onClick={xLink} src={twitter} />
              </div>

              {!walletAddress ?
                (<button class="mintBtn" onClick={connectWallet}>CONNECT</button>) :
                (<button class="mintBtn" onClick={_disconnectWallet}>DISCONNECT</button>)
              }

            </div>

          </div>

        </div>

        <div class="cont">

          {_navbarOpen < 1 ?
            (<div class="miniBarMain">
              <div class="logoDiv"><img onClick={() => window.location.href = 'Mint'} src={logoPic} /></div>

              <div class="navbarMain"><img class="navbar" onClick={navbarOpen} src={navbar} /></div>
            </div>) :
            (<div class="miniBarMain">
              <div class="logoDiv"><img onClick={() => window.location.href = 'Mint'} src={logoPic} /></div>

              <div class="navbarMain"><img class="navbar" onClick={closeNav} src={wrong} /></div>
            </div>)}

          {_navbarOpen > 0 ?
            (<div class="littleNav">


              <div><button class="mintBtn" onClick={presaleOpen}>PRESALE</button></div>

            </div>) : null}

          <div class="introduction">

            <img className='introPicMob' src={frog} />

            <div class="introduction-2">
              <div className='noteMain'>
                <div className='note1'>Leap into Adventure with</div>
                <div className='note2'>FROGY</div>

                {Number(_days) === 0 && Number(_hours) === 0 && Number(_minutes) === 0 && Number(_seconds) === 0 ?
                  <div>
                    <div className='note4'>Presale ended,</div>
                    <div class="timer">
                      Airdrop Time Started
                    </div>
                  </div> :

                  <div>

                    <div className='note4'>Presale ends in,</div>

                    <div class="timer">
                      <div>
                        <div id="days">{Number(_days)}</div>
                        <span>days</span>
                      </div>
                      <div>
                        <div id="hours">{Number(_hours)}</div>
                        <span>hours</span>
                      </div>
                      <div>
                        <div id="minutes">{Number(_minutes)}</div>
                        <span>minutes</span>
                      </div>
                      <div>
                        <div id="seconds">{Number(_seconds)}</div>
                        <span>seconds</span>
                      </div>
                    </div>

                  </div>}

                <div className='note3'
                  onClick={() => {
                    // Copy address to clipboard and handle notification
                    navigator.clipboard.writeText('54RaX2tjjpDcWNSzkPn5ychQauyGBYinnb2ZLvsvPrn8')
                      .then(() => {
                        const notification = document.getElementById('copy-notification');
                        notification.classList.add('show');
                        setTimeout(() => notification.classList.remove('show'), 2000); // Hide after 2 seconds
                      })
                      .catch(error => console.error('Failed to copy address:', error));

                    // Optional: Handle address selection
                  }}
                >
                  <div className='solP'><span className='solF'>1 Solana = 20 000 $FROGY 🐸</span></div>

                  <div className="linkMain">
                    <div className='solP'>Send SOL to <span className='contractAdd'>54RaX2t...LvsvPrn8</span></div>
                    <img className="copy" src={copy} alt="Copy" />
                  </div>

                  <div className='solP'><span className='solF2'>OR</span></div>

                  {/*<center><div className='solP'>{Number(_tokenAmount) * 20000} $FROGY</div></center>*/}

                  <p></p>
                </div>
                <div class="input-container">
                  <input name="TokenAmount" class="input-field" placeholder='SOL Amount' value={_tokenAmount}
                    onChange={event => set_tokenAmount(event.target.value)}></input>
                  <div className='inSol'>SOL</div>
                </div>

                {!_signature ?
                walletAddress ?
                  <button class="mint-btn" onClick={sendMoney2}>{Number(_tokenAmount) * 20000} $FROGY <br /> Order</button> :
                
                  <button class="mint-btn" onClick={connectWallet}>Connect</button>:
                  
                  <button class="mint-btn">{response}</button>
                }

                <img className='noteImg' src={note} />

              </div>
            </div>

            <img className='introPic' src={frog} />

          </div>

        </div>

        <div><img className='imgStone' src={border} /></div>

          <Intro />

          <About />

        {/*<Element name="token">
          <div class="aboutMain--2">
            <div class="aboutMain--2">
              <div className='tokenMain'>
                <div class="conT">Presale $FROGY</div>

                <div class="presale-container">
                  <div className='tokenCon'>
                    Get ready to jump into the future of decentralized finance with FROGY, the Solana-based token that's making waves in the crypto world! Our presale event offers you the exclusive opportunity to get in on the ground floor of this exciting project.
                  </div>

                  <div>

                    <div className='solP'>Price 0.00005 SOL</div>
                    <div className='solP'>Cost {Number(_tokenAmount) * 0.00005} SOL</div>
                    <div class="input-container">
                      <input name="TokenAmount" class="input-field" placeholder='Token Amount' value={_tokenAmount}
                        onChange={event => set_tokenAmount(event.target.value)}></input>

                    </div>
                    <button class="mint-btn" onClick={sendMoney2}>Order $FROGY</button>


                    <div className='note'>{response}</div>

                  </div>

                </div>

              </div>

            </div>
            <div className='gemDiv'>
              <img className='gem' src={gem} />
            </div>
          </div>
        </Element>*/}

        <Footer />

      </div >
    </div >
  )

}
export default Home;
