import React, { Component, useEffect } from 'react';
import '../App.css';
import "aos/dist/aos.css";
import Aos from 'aos';
import shell1 from '../assets/sunglass.png';
import shell2 from '../assets/moreCoins.png';


const Stry = () => {
    useEffect(() => {
        Aos.init({ duration: 4000 });
    }, [])
}

class Intro extends Component {

    render() {
        return (

            <div className='blueBg'>
            <div class="aboutMain--2">
                <p class="conT2">Introducing FROGY, the innovative token on the Solana blockchain designed to hop its way into decentralized finance (DeFi) ecosystems. FROGY isn't just another token; it's a symbol of resilience, adaptability, and community-driven growth.</p>
                <p class="conT2">As its name suggests, FROGY embodies the agility and fluidity of a frog, leaping from one opportunity to the next in the vast landscape of decentralized finance. Built on Solana's lightning-fast blockchain, FROGY aims to revolutionize the way users interact with digital assets, offering swift transactions and low fees.</p>
                <p class="conT2">So whether you're a seasoned DeFi enthusiast or a curious newcomer, join the FROGY community today and leap into a world of limitless possibilities.</p>
                 
            </div>

            <div className='shells2'>

            <img className='shell1' src={shell1}/>
            <img className='shell2' src={shell2}/>

            </div>
            </div>
        )
    }
}

export default Intro;

