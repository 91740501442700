import React, { Component, useEffect } from 'react';
import '../App.css';
import "aos/dist/aos.css";
import Aos from 'aos';
import stone from '../assets/l2.png';
import stone2 from '../assets/rocket.png';

const Stry = () => {
    useEffect(() => {
        Aos.init({ duration: 4000 });
    }, [])
}

class About extends Component {

    render() {
        return (

            <div>
            <div class="aboutMain">
                <div>
                    <div class="conT">ABOUT FROGY</div>
                    <p class="conT2"><span className='conT2-HL'>FROGY</span> stands out not only for its technical prowess and community-centric approach but also for its commitment to environmental sustainability. Utilizing Solana's energy-efficient blockchain technology, FROGY minimizes its carbon footprint, ensuring that every hop in the decentralized ecosystem leaves a positive impact on the planet. By prioritizing sustainability alongside innovation, FROGY sets a new standard for responsible blockchain projects, inspiring others to follow suit in the quest for a greener future. Join us in our mission to revolutionize DeFi while safeguarding the environment—one hop at a time.</p>
               
                </div>
            </div>

            <div className='flexStone'>
            <img className='stoneS' src={stone}/>
            <img className='stoneS2' src={stone2}/>
            </div>
            </div>
        )
    }
}

export default About;